<template>
  <div class="base-content">
    <query-frame
      :current="page.current"
      :total="page.total"
      :add-visible="false"
      @on-query="handleQuery"
      @on-reset="handleReset"
      @on-add="handleAdd"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >
      <!-- 查询条件开始 -->
      <template slot="query">
        <query-item label="车辆类型">
          <a-select v-model="query.kartClassifyId" allowClear placeholder="请选择">
            <a-select-option v-for="item in vehicleCategoryList" :key="item.kartClassifyId" :value="item.kartClassifyId">
              {{item.kartClassifyName}}
            </a-select-option>
          </a-select>
        </query-item>
      </template>
      <!-- 查询条件结束 -->

      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>
  </div>
</template>

<script>
import { storeInfo } from '@/api/system';
import { vehicleRuleList, vehicleAllCateGoryList, vehicleRuleControlSwitch } from '@/api/vehicle';
import queryMixin from '@/mixin/queryMixin';

export default {
  name: 'PriceRuleList',
  mixins: [queryMixin],
  components: {
  },
  props: {},
  data() {
    return {
      query: {
        kartClassifyId: null,
      },

      // 车辆类型类列表
      vehicleCategoryList: [],

      // 默认展示表格
      columns: [
        {
          title: '序号',
          ellipsis: true,
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '门店名称',
          dataIndex: 'shopName',
          key: 'shopName',
          ellipsis: true,
          width: 200,
        },
        {
          title: '车辆类型',
          dataIndex: 'kartClassifyName',
          key: 'kartClassifyName',
          ellipsis: true,
          width: 200,
        },
        {
          title: '车辆规格',
          dataIndex: 'kartClassifySpec',
          key: 'kartClassifySpec',
          ellipsis: true,
          width: 120,
        },
        {
          title: '计费规则',
          colSpan: 2,
          dataIndex: 'priceType1',
          key: 'priceType1',
          ellipsis: true,
          width: 260,
          customRender: (text, row) => {
            return (
              <div>
                <a-tooltip v-show={this.storeInfo.chargeMode === 1} >
                  <template slot="title">
                    当前使用
                  </template>
                  <a href="javascript:;">{row.quantity}圈/原价：{row.quantityPrice}元/会员价：{row.memberQuantityPrice}元</a>
                </a-tooltip>
                <span v-show={this.storeInfo.chargeMode !== 1} class="unuse">{row.quantity}圈/原价：{row.quantityPrice}元/会员价：{row.memberQuantityPrice}元</span>
              </div>
            )
          }
        },
        {
          title: '价格',
          colSpan: 0,
          dataIndex: 'timeBrief',
          key: 'timeBrief',
          ellipsis: true,
          width: 260,
          customRender: (text, row) => {
            return (
              <div>
                <a-tooltip v-show={this.storeInfo.chargeMode === 2}>
                  <template slot="title">
                    当前使用
                  </template>
                  <a href="javascript:;">{row.time}分钟/原价：{row.timePrice}元/会员价：{row.memberTimePrice}元</a>
                </a-tooltip>
                <span v-show={this.storeInfo.chargeMode !== 2} class="unuse">{row.time}分钟/原价：{row.timePrice}元/会员价：{row.memberTimePrice}元</span>
              </div>
            );
          }
        },
        {
          title: '备注',
          dataIndex: 'remarks',
          key: 'remarks',
          ellipsis: true,
          width: 120,
        },
        {
          title: '小程序',
          dataIndex: 'minShow',
          key: 'minShow',
          width: 120,
          customRender: (text, row) => {
            return (
              <a-switch checked-children="显示" un-checked-children="隐藏" checked={row.minShow === 0} onClick={ () => this.handleMinShow(row)}/>
            );
          }
        },
        {
          title: '自助机',
          dataIndex: 'diyShow',
          key: 'diyShow',
          width: 120,
          customRender: (text, row) => {
            return (
              <a-switch checked-children="显示" un-checked-children="隐藏" checked={row.diyShow === 0} onClick={ () => this.handleDiyShow(row)}/>
            );
          }
        },
        {
          title: '队列显示',
          dataIndex: 'queueShow',
          key: 'queueShow',
          width: 120,
          customRender: (text, row) => {
            return (
              <a-switch checked-children="显示" un-checked-children="隐藏" checked={row.queueShow === 0} onClick={ () => this.handleQueueShow(row)}/>
            );
          }
        },
        {
          title: '折上折',
          dataIndex: 'discount',
          key: 'discount',
          ellipsis: true,
          width: 80,
        },
        // {
        //   title: '创建时间',
        //   dataIndex: 'createTime',
        //   key: 'createTime',
        //   ellipsis: true,
        //   width: 160,
        // },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 220,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row) => {
            return {
              children: <section>
                <a-button type="link" size="small" onClick={ () => this.handleInfo(row) }>查看</a-button>
                <a-divider type="vertical"></a-divider>
                <a-button type="link" size="small" onClick={ () => this.handleEdit(row) }>修改</a-button>
              </section>,
            }
          }
        },
      ],
      dataList: [],
      storeInfo: {}
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getStoreInfo();
    this.getVehicleCategoryList();
    this.handleQuery();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取列表
    getData() {
      this.params.pageIndex = this.page.current;
      this.params.pageSize = this.page.pageSize;

      this.dataList = [];
      vehicleRuleList(this.params).then((data) => {
        const { totalCount, list } = data;
        this.page.total = totalCount;
        list.forEach((item) => {
          item.key = item.kartClassifyId;
        });
        this.dataList = list;
        // console.log(this.dataList)
      });
    },

    // 车辆类型列表
    getVehicleCategoryList() {
      vehicleAllCateGoryList().then(({list}) => {
        this.vehicleCategoryList = list;
      });
    },

    // 添加
    handleAdd() {
      this.$router.push({
        name: 'priceRuleAdd'
      });
    },

    handleInfo(row) {
      this.$router.push({
        name: 'priceRuleInfo',
        params: {
          id: row.kartClassifyId
        }
      });
    },
    // 编辑
    handleEdit(row) {
      this.$router.push({
        name: 'priceRuleEdit',
        params: {
          id: row.kartClassifyId
        }
      });
    },
    getStoreInfo() {
      storeInfo().then((data) => {
        this.storeInfo = data;
      });
    },
    handleMinShow(row) {
      const params = {
        kartClassifyId: row.kartClassifyId,
        fieldName: 'minShow',
        fieldValue: row.minShow === 0 ? 1 : 0
      };

      vehicleRuleControlSwitch(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    },
    handleDiyShow(row) {
      const params = {
        kartClassifyId: row.kartClassifyId,
        fieldName: 'diyShow',
        fieldValue: row.diyShow === 0 ? 1 : 0
      };

      vehicleRuleControlSwitch(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    },
    handleQueueShow(row) {
      const params = {
        kartClassifyId: row.kartClassifyId,
        fieldName: 'queueShow',
        fieldValue: row.queueShow === 0 ? 1 : 0
      };

      vehicleRuleControlSwitch(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    },
  }
};
</script>

<style lang="less" >
  .unuse {
    text-decoration: line-through;
  }
</style>
